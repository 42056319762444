jQuery(document).ready(function(){
	jQuery(".search-result.epaper a").magnificPopup({type:'iframe'});
});

function setAction(form, language) {

	var filter = {
		language:['und', language],
		type:[]
	}

	jQuery(".node-search-results input[type=checkbox]").each(function() {
		var $this = jQuery(this);
		if ($this.is(":checked")) {
			var array = ($this.val()).split(",");
			for (var i in array) {
				if (!contains(filter.type, array[i])) {
					filter.type.push(array[i]);
				}
			}              
		}
	});

	var query = "";
	for (var key in filter) {
		if (filter[key].length > 0) {
			query += " "+key+":"+filter[key].join();
		}
	}
	form.action = form.elements[0].value + encodeURIComponent(query);
	return true;
}

function contains(array, needle) {
	var i = array.length;
	while (i--) {
		if (array[i] === needle) {
			return true;
		}
	}
	return false;
}