jQuery(document).ready(function(){

	/* ********************* */
	/* ****** Actions ****** */
	/* ********************* */
	jQuery(".node-pager span").click(function(){
		if (isCodes()) {
			hideCodes();
		} else {
			showCodes();
		}
	}); 
	/* ********************* */
	/* ****** Helpers ****** */
	/* ********************* */
	function isCodes() {
		return jQuery(".node-pager ul").is(":visible");
	}
	function hideCodes(cb) {
		jQuery(".node-pager ul").slideUp(0,cb);
		jQuery(".node-pager").removeClass("active");
	} 
	function showCodes(cb) {
		jQuery(".node-pager ul").slideDown(0,cb);
		jQuery(".node-pager").addClass("active");
	}
});