jQuery(document).ready(function(){

	jQuery(".lightboxlink, .ajaxlink").magnificPopup({type:'iframe'});

 	var hash = location.hash.slice(1);
	location.hash = "";
	if (hash && jQuery('[name=' + hash +']').length) {
		jQuery('html, body').animate({
			scrollTop: jQuery('[name=' + hash +']').offset().top
		}, 1200);
	}

	/* ************************************ */
	/* ****** Resize and Orientation ****** */
	/* ************************************ */
	jQuery(window).resize(function() {
		if (this.resizeTO) clearTimeout(this.resizeTO);
		this.resizeTO = setTimeout(function() {
			jQuery(this).trigger('resizeEnd');
		}, 500); 
	});
	jQuery(window).bind('resizeEnd', function() {
		unsetMobile();
	}.bind(this));
	jQuery(window).bind('orientationchange', function() {
		unsetMobile();
	}.bind(this));
	/* ********************* */
	/* ****** Actions ****** */
	/* ********************* */
	jQuery(".region-header .block-burger").click(function(){
		if (isSearch()) {
			hideSearch(showMenu);
		} else if (isLanguage()) {
			hideLanguage(showMenu);
		} else if (isMenu()) {
			hideMenu();
		} else {
			showMenu();
		}
	});
	jQuery(".region-header .block-glas").click(function(){
		if (isMenu()) {
			hideMenu(showSearch);
		} else if (isLanguage()) {
			hideLanguage(showSearch);
		} else if (isSearch()) {
			hideSearch();
		} else {
			showSearch();
		}
	});
	jQuery(".region-header .block-languages span").click(function(){
		if (isMobile()){
			if (isMenu()) {
				hideMenu(showLanguage);
			} else if (isSearch()) {
				hideSearch(showLanguage);
			} else if (isLanguage()) {
				hideLanguage();
			} else {
				showLanguage();
			}
		} else {
			if (isLanguage()) {
				hideLanguage();
			} else {
				showLanguage();
			}
		}
		if (isAnySubMenu()) {
			hideOtherSubMenu();
		} 
	});
	jQuery(".region-header .block-menu > div > div > ul > li > span").click(function(){
		if (isSubMenu(this)) {
			hideOtherSubMenu();
		} else if (isAnySubMenu()) {
			hideOtherSubMenu(showSubMenu.bind(null, this));
		} else {
			showSubMenu(this);
		}
		if (isLanguage()) {
			hideLanguage();
		} 
	});
	jQuery(".region-content").click (function(){
		hideSubMenu();
	});

	/* ********************* */
	/* ****** Helpers ****** */
	/* ********************* */
	function unsetMobile() {
		jQuery(".region-header .block-menu > div > div > ul > li > div").removeAttr("style");
		jQuery(".region-header .block-menu").removeAttr("style");
		jQuery(".region-header .block-search").removeAttr("style");
		jQuery(".region-header .block-languages").removeClass("active");
		jQuery(".region-header .block-languages ul").removeAttr("style");
		jQuery(".region-header .block-glas").removeClass("active");
		jQuery(".region-header .block-burger").removeClass("active");
	}
	function isMobile() {
		return jQuery(".region-header .block-burger").is(":visible");
	}
	function isMenu() {
		return jQuery(".region-header .block-menu").is(":visible");
	}
	function isSubMenu(parent) {
		return jQuery(parent).siblings("div").is(":visible");
	}
	function isAnySubMenu() {
		return jQuery(".region-header .block-menu > div > div > ul > li > div").is(":visible");
	}
	function isSearch() {
		return jQuery(".region-header .block-search").is(":visible");
	}
	function isLanguage() {
		return jQuery(".region-header .block-languages ul").is(":visible");
	}
	function hideMenu(cb) {
		jQuery(".region-header .block-menu").slideUp(cb);
		jQuery(".region-header .block-burger").removeClass("active");
	}
	function showMenu(cb) {
		jQuery(".region-header .block-menu").slideDown(cb);
		jQuery(".region-header .block-burger").addClass("active");
	}
	function hideSearch(cb) {
		jQuery(".region-header .block-search").slideUp(cb);
		jQuery(".region-header .block-glas").removeClass("active");
	}
	function showSearch(cb) {
		jQuery(".region-header .block-search").slideDown(cb);
		jQuery(".region-header .block-glas").addClass("active");
	}
	function hideLanguage(cb) {
		jQuery(".region-header .block-languages ul").slideUp(cb);
		jQuery(".region-header .block-languages").removeClass("active");
	}
	function showLanguage(cb) {
		jQuery(".region-header .block-languages ul").slideDown(cb);
		jQuery(".region-header .block-languages").addClass("active");
	}
	function hideSubMenu(cb) {
		jQuery(".region-header .block-menu > div > div > ul > li > div").slideUp(cb);
	}
	function hideOtherSubMenu(cb) {
		jQuery(".region-header .block-menu > div > div > ul > li > div:visible").slideUp(cb);
	}
	function showSubMenu(parent, cb) {
		jQuery(parent).siblings("div").slideDown(cb);
	}
	function decodeTag(str) {
		var chars = [];
		var alphabet = " 0123456789abcdefghijklm/no:pqrstuvwxyz.@<=>-\"";
		for (var i = 0; i < str.length; i++) {
			chars.push(alphabet.indexOf(str.charAt(i)));
		}
		return chars;
	}
    function encodeTag(chars) {
		var str = "";
		var alphabet = " 0123456789abcdefghijklm/no:pqrstuvwxyz.@<=>-\"";
		for (var i = 0; i < chars.length; i++)  {
			str += alphabet.charAt(chars[i]);
		}
        return str;
	}
    jQuery(".e-dfs").html(encodeTag([41, 11, 0, 18, 30, 15, 16, 42, 45, 23, 11, 19, 22, 32, 26, 27, 19, 25, 16, 26, 40, 14, 16, 31, 44, 14, 19, 11, 23, 26, 25, 39, 14, 15, 45, 43, 19, 25, 16, 26, 40, 14, 16, 31, 44, 14, 19, 11, 23, 26, 25, 39, 14, 15, 41, 24, 11, 43]));
    jQuery(".e-bsp").html(encodeTag([41, 11, 0, 18, 30, 15, 16, 42, 45, 23, 11, 19, 22, 32, 26, 27, 19, 25, 16, 26, 40, 12, 31, 28, 44, 31, 15, 13, 33, 30, 19, 32, 37, 39, 14, 15, 45, 43, 19, 25, 16, 26, 40, 12, 31, 28, 44, 31, 15, 13, 33, 30, 19, 32, 37, 39, 14, 15, 41, 24, 11, 43]));
    jQuery(".e-lda").html(encodeTag([41, 11, 0, 18, 30, 15, 16, 42, 45, 23, 11, 19, 22, 32, 26, 27, 28, 26, 31, 32, 31, 32, 15, 22, 22, 15, 40, 22, 14, 11, 39, 12, 11, 37, 15, 30, 25, 39, 14, 15, 45, 43, 28, 26, 31, 32, 31, 32, 15, 22, 22, 15, 40, 22, 14, 11, 39, 12, 11, 37, 15, 30, 25, 39, 14, 15, 41, 24, 11, 43]));
});