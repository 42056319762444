jQuery(document).ready(function(){

	jQuery('.node-slider .slides').slick({
		infinite: true,
		slidesToShow: 1,
		slidesToScroll: 1,
		dots:true,
		arrows:false
	});

});
