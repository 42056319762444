jQuery(document).ready(function(){
    jQuery("form input.required, form textarea.required").each(function(){
        var placeholder = jQuery(this).attr("placeholder");
        if (placeholder) {
            jQuery(this).attr("placeholder", placeholder + " *");
        }
    });
    jQuery("form select.required").each(function(){
        jQuery(this).find("option:eq(0)").text(jQuery(this).find("option:eq(0)").text() + " *");
    });
    jQuery(".captcha").appendTo("form > div > fieldset:eq(1) fieldset:eq(1)");
    jQuery(".form-actions").appendTo("form > div > fieldset:eq(1) fieldset:eq(1)");
});