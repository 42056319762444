jQuery(document).ready(function(){
	jQuery(".node-products .catalogs label").click(function(){
		if (jQuery(this).siblings("ul").is(":visible")) {
			jQuery(this).siblings("ul").slideUp();
			jQuery(this).removeClass("opened").addClass("closed");
		} else {
			jQuery(this).siblings("ul").slideDown();
			jQuery(this).removeClass("closed").addClass("opened");
		}
	});
});

